<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 78 78">
    <path fill="currentColor" d="M35.56 72.013l-.729-.567-1.154-4.602s-6.625-1.459-10.65-4.427l-3.93 2.35-.918-.112-5.008-4.992-.113-.914 2.358-3.916c-1.254-1.84-2.262-3.85-3.055-5.965-.16-.42-.314-.842-.454-1.27a28.61 28.61 0 01-.788-2.983c-.025-.121-.065-.237-.088-.359l-4.458-1.11-.568-.726v-7.06l.568-.725 4.458-1.111c.016-.084.044-.162.06-.245.17-.835.366-1.663.607-2.47a28.153 28.153 0 013.719-7.861l-2.359-3.918.113-.914 5.008-4.991.917-.112 3.931 2.35c.176-.118.368-.215.546-.33.49-.318.99-.62 1.5-.908.302-.168.607-.328.916-.485a28.3 28.3 0 011.928-.914c.145-.061.293-.114.439-.173a28.258 28.258 0 015.286-1.563l1.113-4.44.729-.567h3.542V0a38.864 38.864 0 00-19.224 5.067C5.754 13.192 2.24 25.922 2.24 25.922A38.58 38.58 0 000 38.89c0 9.524 3.459 18.255 9.183 25.023 0 0 10.824 14.36 29.919 14.082v-5.983M65.834 48.685c-.593 1.066-.823 2.424-.317 3.908.505 1.474 1.439 2.604 2.711 3.011.507-.53.788-1.259.915-2.017.12-.761.105-1.545-.179-2.297-.606-1.526-1.85-2.394-3.13-2.605z"/>
    <path fill="currentColor" d="M75.568 52.242c-.27 1.66-1.556 3.222-3.123 3.942-1.573.727-3.164.352-4.097-.512.288-.659.643-1.33 1.16-1.907.51-.58 1.2-1.065 1.944-1.445 1.45-.768 3.031-.82 4.116-.078zM63.726 53.174c-.425.451-.717 1.022-.862 1.683a4.704 4.704 0 00-.151 2.102c.241 1.542 1.008 2.85 2.17 3.455.557-.463.963-1.13 1.24-1.84.278-.711.38-1.497.204-2.306-.308-1.595-1.372-2.661-2.6-3.094z"/>
    <path fill="currentColor" d="M72.688 58.368a6.383 6.383 0 01-1.45 2.112 5.94 5.94 0 01-2.327 1.215c-1.674.444-3.132-.165-3.92-1.192.364-.626.833-1.224 1.461-1.69a7.98 7.98 0 012.146-1.096c1.583-.495 3.156-.265 4.09.651zM60.87 57.241c-1.015.72-1.56 2.053-1.67 3.544-.06 1.527.507 2.992 1.535 3.774 1.219-.768 2.201-2.19 2.14-3.837-.01-1.61-.866-2.84-2.004-3.48z"/>
    <path fill="currentColor" d="M68.794 63.905c-.912 1.383-2.517 2.544-4.31 2.611-1.756.117-3.049-.69-3.659-1.852.893-1.154 2.412-1.875 4.035-2.125 1.661-.199 3.177.306 3.934 1.366zM57.34 60.752c-.543.293-1.05.686-1.423 1.245-.374.56-.651 1.24-.835 1.957-.335 1.475-.036 3.009.844 3.96a4.92 4.92 0 001.825-1.261c.478-.611.818-1.35.959-2.132a3.916 3.916 0 00-1.37-3.769z"/>
    <path fill="currentColor" d="M63.99 68.679c-.549.625-1.313 1.103-2.118 1.446-.806.345-1.687.51-2.58.385-1.761-.205-2.898-1.23-3.295-2.476a5.037 5.037 0 011.983-1.044 5.875 5.875 0 012.37-.337c1.697.124 3.065.838 3.64 2.026zM67.137 43.904c-.183.58-.311 1.205-.267 1.865.046.66.276 1.359.65 2.042.668 1.334 1.874 2.315 3.17 2.484.793-1.232.727-3.012-.036-4.376-.784-1.38-2.25-2.036-3.517-2.015z"/>
    <path fill="currentColor" d="M77.345 45.711c.027.84-.245 1.697-.67 2.47-.425.775-.99 1.469-1.7 1.96-1.51.961-3.054.9-4.154.2.318-1.404 1.068-2.845 2.465-3.84 1.371-.994 2.829-1.336 4.059-.79zM67.607 38.956a3.512 3.512 0 00.033 1.89c.202.647.533 1.295.968 1.897.92 1.203 2.29 1.96 3.588 1.9a4.886 4.886 0 00.2-2.204 4.78 4.78 0 00-1.025-2.102c-1.033-1.226-2.475-1.62-3.764-1.38z"/>
    <path fill="currentColor" d="M77.974 38.98c.14 1.639-.269 3.561-1.62 4.76-1.29 1.214-2.81 1.424-4.021.926a5.001 5.001 0 01.47-2.186 6.018 6.018 0 011.262-2.025c1.157-1.217 2.649-1.8 3.91-1.475zM67.157 34.007c-.036.617.108 1.243.422 1.842a4.877 4.877 0 001.238 1.709c1.148 1.028 2.644 1.537 3.904 1.255.09-.711.005-1.483-.247-2.204a3.887 3.887 0 00-1.331-1.897c-1.263-1.025-2.766-1.16-3.986-.705z"/>
    <path fill="currentColor" d="M77.373 32.247c.25.79.34 1.682.25 2.547a5.738 5.738 0 01-1.003 2.419c-1.026 1.413-2.47 1.881-3.759 1.599a4.97 4.97 0 01.022-2.231c.195-.76.514-1.519.936-2.215.893-1.39 2.248-2.222 3.554-2.119zM65.88 29.219c.241 1.194.92 2.387 2.278 3.195 1.33.807 2.778 1.074 4.014.571.047-.73-.2-1.47-.597-2.131-.396-.661-.93-1.247-1.612-1.647-1.454-.779-2.968-.646-4.083.012z"/>
    <path fill="currentColor" d="M75.63 25.708c.871 1.441.874 3.469.152 5.02-.743 1.566-2.2 2.298-3.473 2.233-.203-.69-.356-1.431-.339-2.204.02-.771.218-1.58.564-2.354.609-1.516 1.786-2.568 3.096-2.695z"/>
    <g>
      <path fill="currentColor" d="M63.795 24.72c.234.562.532 1.127.998 1.6.457.477 1.102.859 1.81 1.15 1.376.593 2.934.57 4.033-.129-.194-1.45-1.374-2.778-2.85-3.33-1.484-.557-3.035-.118-3.99.709z"/>
      <path fill="currentColor" d="M72.78 19.566c.563.626.9 1.46 1.078 2.322.172.866.202 1.751-.044 2.591-.533 1.703-1.754 2.644-3.048 2.814-.67-1.277-1.005-2.869-.589-4.521.417-1.642 1.31-2.836 2.604-3.206z"/>
    </g>
    <g>
      <path fill="currentColor" d="M60.96 20.637c.294.541.689 1.046 1.25 1.418.561.373 1.244.65 1.964.833 1.48.333 3.018.035 3.973-.842a4.903 4.903 0 00-1.266-1.819 4.923 4.923 0 00-2.14-.955 3.944 3.944 0 00-3.78 1.365z"/>
      <path fill="currentColor" d="M68.914 14.01c.627.547 1.107 1.308 1.451 2.11.346.804.512 1.681.387 2.57-.206 1.757-1.234 2.89-2.484 3.286a5.009 5.009 0 01-1.048-1.977 5.819 5.819 0 01-.338-2.362c.124-1.692.84-3.056 2.032-3.628z"/>
    </g>
    <g>
      <path fill="currentColor" d="M57.438 17.118c.722 1.013 2.059 1.557 3.555 1.666 1.532.059 3.001-.505 3.787-1.53-.77-1.215-2.197-2.194-3.85-2.133-1.615.01-2.849.863-3.492 1.997z"/>
      <path fill="currentColor" d="M64.124 9.221c1.388.91 2.553 2.509 2.62 4.295.117 1.75-.692 3.039-1.858 3.647-1.159-.89-1.881-2.404-2.132-4.021-.2-1.654.307-3.165 1.37-3.92z"/>
    </g>
    <g>
      <path fill="currentColor" d="M53.356 14.273c.453.423 1.026.714 1.69.86.627.197 1.339.26 2.108.15 1.548-.24 2.86-1.005 3.467-2.164-.465-.554-1.135-.959-1.847-1.234a3.99 3.99 0 00-2.313-.204c-1.6.306-2.67 1.367-3.105 2.592z"/>
      <path fill="currentColor" d="M58.568 5.34c.77.317 1.511.822 2.119 1.446a5.905 5.905 0 011.22 2.32c.445 1.668-.166 3.12-1.196 3.907-.629-.363-1.228-.83-1.696-1.457a7.932 7.932 0 01-1.1-2.139c-.497-1.577-.266-3.145.653-4.076z"/>
    </g>
    <g>
      <path fill="currentColor" d="M48.852 12.172c1.07.592 2.432.82 3.922.316 1.478-.503 2.612-1.434 3.02-2.702-.531-.505-1.262-.785-2.023-.911-.764-.12-1.55-.105-2.305.177-1.53.605-2.401 1.844-2.614 3.12z"/>
      <path fill="currentColor" d="M52.42 2.47c1.666.27 3.234 1.551 3.956 3.112.73 1.568.354 3.154-.513 4.084-.661-.288-1.334-.641-1.914-1.156-.582-.508-1.068-1.196-1.45-1.938-.77-1.444-.822-3.02-.078-4.102z"/>
    </g>
    <g>
      <path fill="currentColor" d="M44.054 10.874c.583.182 1.21.31 1.872.266.661-.047 1.363-.276 2.049-.65 1.338-.664 2.322-1.866 2.492-3.157-1.236-.791-3.022-.725-4.39.036a4.008 4.008 0 00-2.023 3.505z"/>
      <path fill="currentColor" d="M45.868.7c.843-.026 1.703.244 2.479.668.777.422 1.473.986 1.965 1.694.965 1.505.903 3.044.202 4.14-1.41-.317-2.855-1.065-3.854-2.457-.997-1.367-1.34-2.819-.792-4.045z"/>
    </g>
    <g>
      <path fill="currentColor" d="M39.09 10.405c.605.162 1.249.15 1.896-.033.65-.201 1.3-.531 1.903-.964 1.207-.917 1.967-2.283 1.907-3.576a4.934 4.934 0 00-2.212-.2 4.81 4.81 0 00-2.109 1.022c-1.23 1.03-1.625 2.467-1.385 3.751z"/>
      <path fill="currentColor" d="M39.113.073c1.644-.14 3.573.268 4.776 1.615 1.218 1.286 1.428 2.8.929 4.007a5.048 5.048 0 01-2.193-.47 6.047 6.047 0 01-2.033-1.256c-1.22-1.153-1.806-2.64-1.48-3.896z"/>
    </g>
    <g>
      <path fill="currentColor" d="M53.44 63.337c-.598.166-1.144.503-1.613.993a4.719 4.719 0 00-1.223 1.72c-.596 1.442-.62 2.957.06 4.076.715-.109 1.41-.47 2.016-.934.607-.464 1.105-1.082 1.375-1.865.566-1.523.21-2.985-.614-3.99z"/>
      <path fill="currentColor" d="M58.401 72.41a6.406 6.406 0 01-2.339 1.056 5.947 5.947 0 01-2.621-.164c-1.664-.484-2.594-1.759-2.733-3.045.637-.348 1.35-.616 2.129-.689.78-.072 1.61-.002 2.405.171 1.61.395 2.837 1.405 3.16 2.67z"/>
    </g>
    <g>
      <path fill="currentColor" d="M48.882 65.34c-1.244.09-2.402.948-3.272 2.165-.846 1.275-1.123 2.82-.65 4.021 1.441-.027 3.02-.734 3.825-2.175.83-1.381.737-2.874.097-4.011z"/>
      <path fill="currentColor" d="M52.19 75.131c-1.5.711-3.475.875-5.043.005-1.562-.808-2.248-2.165-2.165-3.474 1.364-.526 3.037-.356 4.555.269 1.524.688 2.556 1.903 2.654 3.2z"/>
    </g>
    <g>
      <path fill="currentColor" d="M44.036 66.517c-.617-.031-1.255.045-1.864.329-.61.286-1.202.724-1.732 1.243-1.054 1.088-1.595 2.553-1.339 3.822a4.935 4.935 0 002.217-.136 4.916 4.916 0 001.929-1.328 3.915 3.915 0 00.79-3.93z"/>
      <path fill="currentColor" d="M45.599 76.731c-.794.251-1.696.265-2.564.142-.869-.122-1.708-.435-2.405-1.004-1.4-1.085-1.84-2.55-1.53-3.82a5.044 5.044 0 012.239.132c.755.15 1.507.455 2.202.937 1.387.983 2.184 2.301 2.058 3.613z"/>
    </g>
    <path fill="currentColor" d="M22.784 15.224l.487.235.182-.11-.106-.702-.484.286-.079.29z"/>
    <g clip-path="url(#sp-logo)">
      <path fill="currentColor" d="M27.2 26.012h-.002a1.211 1.211 0 00-1.21 1.217v18.244a1.22 1.22 0 001.218 1.216c2.832.007 7.576.597 10.85 4.023V31.619c0-.227-.058-.44-.168-.616-2.686-4.327-7.849-4.984-10.687-4.99zM52.012 45.473V27.229a1.211 1.211 0 00-1.21-1.217H50.8c-2.838.007-8 .664-10.687 4.99-.11.177-.167.39-.167.617V50.71c3.273-3.425 8.017-4.015 10.85-4.022a1.22 1.22 0 001.217-1.216z"/>
      <path fill="currentColor" d="M54.784 30.22H53.9v15.253a3.113 3.113 0 01-3.102 3.105c-2.402.006-6.363.476-9.168 3.13 4.852-1.187 9.966-.415 12.88.249a1.215 1.215 0 001.49-1.185V31.436c0-.67-.547-1.216-1.217-1.216zM24.099 45.473V30.22h-.883c-.67 0-1.216.545-1.216 1.216v19.335a1.214 1.214 0 001.489 1.185c2.914-.664 8.029-1.436 12.88-.248-2.805-2.655-6.766-3.124-9.168-3.13a3.113 3.113 0 01-3.102-3.105z"/>
    </g>
    <defs>
      <clipPath id="sp-logo">
        <path fill="currentColor" d="M0 0h34v34H0z" transform="translate(22 22)"/>
      </clipPath>
    </defs>
  </svg>
</template>