import axios from "axios";
import config from "./axiosconfig";
import urlTemplate from "@/shared/url-template";
import resources from "./resources";

export default {
  getEntityField: async(entityName, id, fieldName) =>
    (await axios.get(urlTemplate(resources.dependent.getEntityField, { entityName, id, fieldName }), config)).data,
  gridData: async (targetType, sourceType, id, programId, filters = {}) =>
    (await axios.post(urlTemplate(resources.dependent.gridData, { targetType, sourceType, id, programId }), { filters: filters }, config)).data,
  getReferenceOptions: async (targetType, sourceType, id) =>
    (await axios.get(urlTemplate(resources.dependent.gridData, { targetType, sourceType, id }), config)).data,
}
