<template>
  <a-layout-sider
    :width="$viewport.menuWidth"
    :collapsible="$viewport.state.isMobileView ? false : true"
    :collapsed="
      $viewport.state.isMenuCollapsed && !$viewport.state.isMobileView
    "
    :class="{
      [$style.menu]: true,
      [$style.white]: true,
    }"
    @collapse="onCollapse"
  >
    <div
      :class="$style.menuOuter"
      :style="{
        width:
          $viewport.state.isMenuCollapsed && !$viewport.state.isMobileView
            ? '80px'
            : $viewport.menuWidth + 'px',
        height:
          $viewport.state.isMobileView || $viewport.state.isMenuUnfixed
            ? 'calc(100% - 64px)'
            : 'calc(100% - 110px)',
      }"
    >
      <div :class="$style.logoContainer">
        <div :class="$style.logo">
          <a href="/">
            <sp-logo />
            <span v-if="!$viewport.state.isMenuCollapsed">Реестр примерных<br /> образовательных<br /> программ СПО</span>
          </a>
        </div>
      </div>
      <perfect-scrollbar :style="{ height: '100%' }">
        <a-menu
          v-model:open-keys="openKeys"
          :mode="'inline'"
          :selected-keys="selectedKeys"
          :inline-indent="15"
          :class="$style.navigation"
          @click="handleClick"
          @openChange="handleOpenChange"
        >
          <template v-for="(item, index) in menuData">
            <template
              v-if="
                !item.roles || item.roles.includes($auth.state.userRole?.id)
              "
            >
              <a-menu-item-group v-if="item.category" :key="index">
                <template #title>
                  {{ item.title }}
                </template>
              </a-menu-item-group>
              <item
                v-if="!item.children && !item.category"
                :key="item.key"
                :menu-info="item"
                :styles="$style"
              />
              <sub-menu
                v-if="item.children"
                :key="item.key"
                :menu-info="item"
                :styles="$style"
              />
            </template>
          </template>
        </a-menu>
      </perfect-scrollbar>
    </div>
  </a-layout-sider>
</template>

<script>
import { ref, onMounted, computed, watch, getCurrentInstance } from "vue";
import { useRoute } from "vue-router";
import { default as localStore } from "store";
import find from "lodash/find";
import SubMenu from "./partials/submenu";
import Item from "./partials/item";
import SpLogo from  "@/components/base/sp-logo"

export default {
  name: "sp-main-menu-content",
  components: { SubMenu, Item, SpLogo },
  setup() {
    const globals = getCurrentInstance().appContext.config.globalProperties;
    const route = useRoute();
    const menuData = computed(() => globals.$menu.mainMenu.value);
    const selectedKeys = ref([]);
    const openKeys = ref([]);
    const isMenuCollapsed = computed(
      () => globals.$viewport.state.isMenuCollapsed
    );
    const pathname = computed(() => route.pathname);

    const onCollapse = (/*collapsed, type*/) => {
      globals.$viewport.state.isMenuCollapsed = !globals.$viewport.state
        .isMenuCollapsed;
    };

    const handleClick = (e) => {
      localStore.set("app.menu.selectedKeys", [e.key]);
      selectedKeys.value = [e.key];
    };

    const handleOpenChange = (openKeys) => {
      localStore.set("app.menu.openedKeys", openKeys);
      openKeys.value = openKeys;
    };

    const setSelectedKeys = () => {
      const flattenItems = (items, key) =>
        items.reduce((flattenedItems, item) => {
          flattenedItems.push(item);
          if (Array.isArray(item[key])) {
            return flattenedItems.concat(flattenItems(item[key], key));
          }
          return flattenedItems;
        }, []);
      const selectedItem = find(
        flattenItems(menuData.value.concat(), "children"),
        ["url", pathname]
      );
      selectedKeys.value = selectedItem ? [selectedItem.key] : [];
    };

    onMounted(() => {
      openKeys.value = localStore.get("app.menu.openedKeys") || [];
      selectedKeys.value = localStore.get("app.menu.selectedKeys") || [];
      setSelectedKeys();
    });

    watch(pathname, () => setSelectedKeys());
    watch(isMenuCollapsed, () => (openKeys.value = []));

    return {
      menuData,
      selectedKeys,
      openKeys,
      onCollapse,
      handleClick,
      handleOpenChange,
    };
  },
};
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
