import crud from "./crud";
import entityConfig from "./entity-config";
import entityDynamicField from "./entity-dynamic-field";
import reference from "./reference";
import subtable from "./subtable";
import subtableEx from "./subtable-ex";
import dependent from "./dependent";
import filestorage from "./filestorage";
import userManager from "./user-manager";
import programStatus from "./program-status";
import programTitle from "./program-title";
import comment from "./comment";
import news from "./news";
import registrynews from "./registrynews";
import usefulResources from "./useful-resources";
import diagramchart from "./diagramchart";
import labchart from "./labchart";
import upload from "./upload";
import barchart from "./barchart";
import contacts from "./contacts";
import dataslicestatistic from "./dataslicestatistic";
export default {
  crud,
  filestorage,
  entityConfig,
  entityDynamicField,
  reference,
  subtable,
  subtableEx,
  dependent,
  userManager,
  programStatus,
  programTitle,
  comment,
  news,
  registrynews,
  usefulResources,
  diagramchart,
  labchart,
  upload,
  barchart,
  contacts,
  dataslicestatistic,
};
