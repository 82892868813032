export function required(fieldTitle) {
  return {
    required: true,
    message: "Заполните поле \"" + fieldTitle + "\"",
  }
}

export function email() {
  return {
    trigger: 'blur', // less awful than standard change event
    type: 'email',
    message: 'Неверный формат эл. почты (пример: xxx@yyy.zz)'
  }
}