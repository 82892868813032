<template>
  <menu-left v-if="!$viewport.state.isMobileView" />
  <div v-if="$viewport.state.isMobileView">
    <div :class="$style.handler" @click="$viewport.toggleMobileMenu">
      <div :class="$style.handlerIcon"></div>
    </div>
    <a-drawer
      :closable="false"
      :visible="$viewport.state.isMobileMenuOpen"
      placement="left"
      :wrap-class-name="$style.mobileMenu"
      @close="$viewport.toggleMobileMenu"
    >
      <menu-left />
    </a-drawer>
  </div>
</template>

<script>
import MenuLeft from './sp-main-menu-content'

export default {
  name: "sp-main-menu",
  components: { MenuLeft }
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
