import axios from "axios";
import config from "./axiosconfig";
import urlTemplate from "@/shared/url-template";
import resources from "./resources";

export default {
  getNews: async (page) =>
    (await axios.get(urlTemplate(resources.news.getNews, {page}), config)).data,
  getHeroNews: async () =>
    (await axios.get(urlTemplate(resources.news.getHeroNews, {} ), config)).data,
  getSingleNews: async (id) =>
    (await axios.get(urlTemplate(resources.news.getSingleNews, {id} ), config)).data,
}
