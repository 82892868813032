<template>
  <a-config-provider :locale="localeAntd">
    <slot />
  </a-config-provider>
</template>

<script>
import { ref } from 'vue'
import { createI18n, useI18n } from 'vue-i18n/dist/vue-i18n.esm-browser.prod'

import english from '@/locales/en-US'
import russian from '@/locales/ru-RU'

const locales = {
  'en-US': english,
  'ru-RU': russian,
}

export const i18n = createI18n({
  locale: 'ru-RU',
  fallbackLocale: 'ru-RU',
  messages: {
    'en-US': locales['en-US'].messages,
    'ru-RU': locales['ru-RU'].messages,
  },
})

export default {
  name: 'Localization',
  setup() {
    const { locale } = useI18n()
    const localeAntd = ref(locales['ru-RU'].localeAntd)

    // set locale from settings on app load
    locale.value = 'ru-RU'

    // watch locale settings change and apply
    // watch(settingsLocale, settingsLocale => {
    //   locale.value = settingsLocale
    //   localeAntd.value = locales[settingsLocale].localeAntd
    // })

    return {
      localeAntd,
    }
  },
}
</script>
