<template>
  <a-modal 
    v-model:visible="innerVisible"
    :confirm-loading="loading"
    @ok="onOk"
    @cancel="onCancel"
  >
    <div class="text-dark font-size-32 mb-3">Войти</div>
    <div v-if="wrongPassword">
      Логин или пароль указаны неверно
    </div>
    <a-form
      :model="loginForm"
      :rules="rules"
      ref="form"
      layout="vertical"
      class="mb-4"
      @finish="sendData"
      @finishFailed="handleFinishFailed"
    >
      <a-form-item name="login">
        <a-input v-model:value="loginForm.login" placeholder="Логин" />
      </a-form-item>
      <a-form-item name="password">
        <a-input v-model:value="loginForm.password" placeholder="Пароль" type="password" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { required } from "@/helpers/validation";

export default {
  name: "sp-login-dialog",
  data() {
    return {
      innerVisible: false,
      loginForm: {
        login: null,
        password: null,
      },
      loading: false,
      wrongPassword: false,
      rules: {
        login: [required("Логин")],
        password: [required("Пароль")],
      }
    }
  },
  props: {
    visible: { type: Boolean, required: true }
  },
  emits: ["close"],
  watch: {
    visible: {
      handler(value) {
        if (value != this.innerVisible)
          this.innerVisible = value;
      },
      immediate: true
    }
  },
  methods: {
    async onOk() {
      await this.$refs.form.validate();
      await this.sendData();
    },
    async sendData() {
      this.loading = true;
      let result = false;
      try {
        result = await this.$auth.login(this.loginForm.login, this.loginForm.password);
      }
      catch (e) {
        console.log(e);
      }
      this.loading = false;
      this.wrongPassword = !result;
      if (result)
        this.$emit("close", true);
    },
    onCancel() {
      this.$emit("close", false);
    },
    handleFinishFailed(e) {
      console.log(e);
    }
  }
}
</script>

<style lang="scss" module>
@import '@/@vb/components/Auth/style.module.scss';
</style>