import axios from "axios";
import config from "./axiosconfig";
import urlTemplate from "@/shared/url-template";
import resources from "./resources";

export default {
  upload: async (directory, filename) =>
    (await axios.get(urlTemplate(resources.filestorage.uploadFile, { directory, filename }), config)).data,
  download: async (id) =>
    (await axios.get(urlTemplate(resources.filestorage.downloadFile, { id }), config)).data,
  getInfo: async (id) =>
    (await axios.get(urlTemplate(resources.filestorage.getInfo, { id }), config)).data,
  
}
