import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import authedRouter from './router/authedRouter';
import Antd from 'ant-design-vue';
import axios from 'axios';
import authService from './services/auth-service';
import menuService from './services/menu-service';
import viewportService from './services/viewport-service';
import { i18n } from './localization';
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import { loadConfig } from './config-loader';

loadConfig().then(init);

async function init() {
  axios.defaults.withCredentials = true;

  const app = createApp(App)
  app.use(Antd);
  app.use(i18n);
  app.use(PerfectScrollbar);

  app.config.globalProperties.$menu = menuService;
  app.config.globalProperties.$viewport = viewportService;
  app.config.globalProperties.$auth = authService;
  await authService.loadCurrentUser();
  app.use(router);
  authedRouter.use(router, { auth: authService, notloggedin: '/notloggedin' });
  app.mount('#app');
  menuService.initMenu();
}