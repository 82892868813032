import localeAntd from 'ant-design-vue/lib/locale-provider/ru_RU'

const messages = {
  topBar: {
    issuesHistory: 'Задания',
    projectManagement: 'Проекты',
    typeToSearch: 'Поиск...',
    findPages: 'Поиск страниц...',
    actions: 'Действия',
    status: 'Статус',
    profileMenu: {
      hello: 'Привет',
      billingPlan: 'Тарифный план',
      role: 'Роль',
      email: 'Email',
      phone: 'Телефон',
      editProfile: 'Редактировать профиль',
      logout: 'Выйти',
      login: 'Войти',
    },
  },
  formConfig: {
    widget: {
      string: "Строка",
      text: "Текст",
      number: "Число",
      boolean: "Да/Нет",
      date: "Дата",
      dateperiod: "Период дат",
      reference: "Выбор из справочника",
      commentlist: "Комментарии",
      filecollection: "Файлы",
      subtable: "Вложенная таблица",
      multipleReference: "Множественный выбор",
      dependent: "Зависимое поле",
      dependentsubtable: "Зависимая таблица",
      dependentreference: "Зависимый выбор",
      grid: "Разметка",
      tabs: "Вкладки",
      label: "Надпись"
    },
    property: {
      model: 'ID',
      label: 'Название',
      width: 'Ширина',
      height: 'Высота',
      size: 'Размер',
      labelWidth: 'Ширина названия',
      showLabel: 'Показывать название',
      hideLabel: 'Скрыть название',
      labelVertical: 'Название сверху',
      placeholder: 'Placeholder',
      left: 'Слева',
      right: 'Справа',
      center: 'В центре',
      min: 'Минимум',
      max: 'Максимум',
      step: 'Шаг',
      multiple: 'Множественный выбор',
      filterable: 'С поиском',
      option: 'Option',
      staticData: 'Static Data',
      remoteData: 'Remote Date',
      remoteFunc: 'Remote Function',
      value: 'Значение',
      childrenOption: 'Sub-Option',
      defaultValue: 'Default Value',
      showType: 'Display type',
      isRange: 'Range Time',
      isTimestamp: 'Get time stamp',
      startPlaceholder: 'Placeholder начала',
      endPlaceholder: 'Placeholder окончания',
      defaultType: 'Тип данных',
      string: 'String',
      object: 'Object',
      array: 'Array',
      number: 'Number',
      boolean: 'Boolean',
      integer: 'Integer',
      float: 'Float',
      url: 'URL',
      email: 'E-mail',
      hex: 'Hexadecimal',
      gutter: 'Расстояние между ячейками',
      columnOption: 'Колонки',
      span: 'Ширина',
      justify: 'Горизонтальное выравнивание',
      justifyStart: 'В начале',
      justifyEnd: 'В конце',
      justifyCenter: 'В центре',
      justifySpaceAround: 'С пространством вокруг',
      justifySpaceBetween: 'С пространством между ячейками',
      align: 'Вертикальное выравнивание',
      alignTop: 'Сверху',
      alignMiddle: 'Посередине',
      alignBottom: 'Снизу',
      type: 'Type',
      default: 'Default',
      card: 'Tabs',
      borderCard: 'Border-Card',
      tabPosition: 'Tab Position',
      top: 'Top',
      bottom: 'Bottom',
      tabOption: 'Список вкладок',
      tabName: 'Название',
      customClass: 'Custom Class',
      attribute: 'Attribute Action',
      dataBind: 'Data Binding',
      hidden: 'Hidden',
      readonly: 'Read Only',
      disabled: 'Disabled',
      editable: 'Text box is editable',
      clearable: 'Кнопка очистки',
      arrowControl: 'Use the arrow for time selection',
      isDelete: 'Deletable',
      isEdit: 'Editable',
      showPassword: 'Display Password',
      validate: 'Validation',
      required: 'Required',
      patternPlaceholder: 'Fill in the regular expressions',
      newOption: 'New Option',
      validatorRequired: 'Required',
      validatorType: 'Invaild format',
      validatorPattern: 'Unmatched pattern',
      editableStatuses: 'Доступно в статусах',
      bold: 'Полужирный',
      italic: 'Наклонный',
      precision: 'Кол-во цифр после запятой',
    },
    action: {
      addColumn: 'Добавить колонку',
      addTab: 'Добавить вкладку'
    }
  }
}

export default {
  locale: 'ru-RU',
  localeAntd,
  messages,
}
