<template>
  <div>
    <a-dropdown :trigger="['click']" placement="bottomLeft">
      <div :class="$style.dropdown">
        <a-avatar shape="square" size="large" :class="$style.avatar">
          <template #icon><UserOutlined /></template>
        </a-avatar>
      </div>
      <template #overlay>
        <a-menu v-if="isLoggedIn">
          <a-menu-item>
            <div>
              <strong class="mr-1">{{ $t('topBar.profileMenu.role') }}:</strong>
              {{ user?.userRole?.title || '—' }}
            </div>
            <div>
              <strong class="mr-1">{{ $t('topBar.profileMenu.email') }}:</strong>
              {{ user?.login || '—' }}
            </div>
          </a-menu-item>
          <a-menu-divider />
          <a-menu-item  @click="logout">
            <a href="javascript: void(0);">
              <i class="fe fe-log-out mr-2" />
              {{ $t('topBar.profileMenu.logout') }}
            </a>
          </a-menu-item>
        </a-menu>
        <a-menu v-else>
          <a-menu-item @click="loginDialogVisible = true">
            <a href="javascript: void(0);">
              <i class="fe fe-log-out mr-2" />
              {{ $t('topBar.profileMenu.login') }}
            </a>
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
    <sp-login-dialog
      :visible="loginDialogVisible"
      @close="onLoginDialogClose"
    ></sp-login-dialog>
  </div>
</template>

<script>
import { UserOutlined } from '@ant-design/icons-vue'
import SpLoginDialog from '@/components/sp-login-dialog.vue';

export default {
  name: 'sp-topbar-usermenu',
  components: {
    UserOutlined,
    SpLoginDialog,
  },
  data() {
    return {
      loginDialogVisible: false,
    }
  },
  methods: {
    async onLoginDialogClose(success) {
      this.loginDialogVisible = false;
      if (!success)
        return;
      
      let redirect = this.$route.query?.redirect;
      if (redirect)
        this.$router.replace(redirect);

      location.reload();
    },
    async logout() {
      await this.$auth.logout(); 
      location.reload();
    }
  },
  computed: {
    isLoggedIn() {
      return this.$auth.isLoggedIn();
    },
    user() {
      return this.$auth.state.user;
    } 
  }
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
