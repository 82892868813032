import { reactive } from "vue";

let state = reactive({
  isMobileView: false,
  isTabletView: false,
  isMobileMenuOpen: false,
  isMenuCollapsed: false,
});

const setViewPort = (isMobileView = false, isTabletView = false) => {
  state.isMobileView = isMobileView;
  state.isTablet = isTabletView;
};

const detectViewPort = (firstLoad = false) => {
  const isMobile = state.isMobileView
  const isTablet = state.isTabletView
  const width = window.innerWidth
  const currentState = {
    next: {
      mobile: width < 768,
      tablet: width < 992,
      desktop: !(width < 768) && !(width < 992),
    },
    prev: {
      mobile: isMobile,
      tablet: isTablet,
      desktop: !isMobile && !isTablet,
    },
  }
  // desktop
  if (currentState.next.desktop && (currentState.next.desktop !== currentState.prev.desktop || firstLoad)) {
    setViewPort(false, false)
  }
  // tablet & collapse menu
  if (
    currentState.next.tablet &&
    !currentState.next.mobile &&
    (currentState.next.tablet !== currentState.prev.tablet || firstLoad)
  ) {
    setViewPort(false, true)
    state.isMenuCollapsed = true;
  }
  // mobile
  if (currentState.next.mobile && (currentState.next.mobile !== currentState.prev.mobile || firstLoad)) {
    setViewPort(true, false)
  }
}

const toggleMobileMenu = () => {
  state.isMobileMenuOpen = !state.isMobileMenuOpen;
};
let touchStartPrev = 0;
let touchStartLocked = false;
const bindMobileSlide = () => {
  // mobile menu touch slide opener
  const unify = e => {
    return e.changedTouches ? e.changedTouches[0] : e
  }
  document.addEventListener(
    'touchstart',
    e => {
      const x = unify(e).clientX
      touchStartPrev = x
      touchStartLocked = x > 70
    },
    { passive: false },
  )
  document.addEventListener(
    'touchmove',
    e => {
      const x = unify(e).clientX
      const prev = touchStartPrev
      if (x - prev > 50 && !touchStartLocked) {
        toggleMobileMenu()
        touchStartLocked = true
      }
    },
    { passive: false },
  )
}

export default {
  state,
  detectViewPort,
  detectViewPortListener() {
    detectViewPort(false);
  },
  bindMobileSlide,
  menuWidth: 260,
}