import axios from "axios";
import config from "./axiosconfig";
import urlTemplate from "@/shared/url-template";
import resources from "./resources";

export default {
  getYears: async () =>
    (await axios.get(urlTemplate(resources.barchart.getYears, {} ), config)).data,
  getData: async (filter) =>
    (await axios.post(urlTemplate(resources.barchart.getData, {} ), filter, config)).data,
  getLabs: async (filter) =>
    (await axios.post(urlTemplate(resources.barchart.getLabs, {} ), filter, config)).data,
}
