<template>
  <div :class="$style.topbar">
    <sp-user-menu style="margin-left: auto;" />
  </div>
</template>

<script>
import SpUserMenu from './sp-topbar-usermenu'

export default {
  name: 'sp-topbar',
  components: {
    SpUserMenu,
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
