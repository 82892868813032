import axios from "axios";
import config from "./axiosconfig";
import urlTemplate from "@/shared/url-template";
import resources from "./resources";

export default {
  represent: async (represent) =>
    (await axios.get(urlTemplate(resources.entityConfig.represent, { represent: represent }), config)).data,
  representEntityFields: async (represent) =>
    (await axios.get(urlTemplate(resources.entityConfig.representEntityFields, { represent: represent }), config)).data,
  representRefreshListviewPresets: async () => 
    (await axios.post(resources.entityConfig.representRefreshListviewPresets, config)).data,
  filteredFields: async () =>
    (await axios.get(urlTemplate(resources.entityConfig.filteredFields), config)).data,
  userRoleTypes: async () =>
    (await axios.get(urlTemplate(resources.entityConfig.userRoleTypes), config)).data,
}
