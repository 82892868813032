import axios from "axios";
import config from "./axiosconfig";
import urlTemplate from "@/shared/url-template";
import resources from "./resources";

export default {
  getResources: async (page) =>
    (await axios.get(urlTemplate(resources.usefulResources.getResources, { page }), config)).data,
  getSingleResource: async (id) =>
    (await axios.get(urlTemplate(resources.usefulResources.getSingleResource, { id } ), config)).data,
}
