import localeAntd from 'ant-design-vue/lib/locale-provider/en_US'

const messages = {
  topBar: {
    issuesHistory: 'Issues',
    projectManagement: 'Project',
    typeToSearch: 'Search...',
    findPages: 'Find pages...',
    actions: 'Actions',
    status: 'Status',
    profileMenu: {
      hello: 'Hello',
      billingPlan: 'Billing Plan',
      role: 'Role',
      email: 'Email',
      phone: 'Phone',
      editProfile: 'Edit Profile',
      logout: 'Logout',
      login: 'Login',
    },
  },
  formConfig: {
    widget: {
      string: "String",
      text: "Text",
      number: "Number",
      boolean: "Boolean",
      date: "Date",
      dateperiod: "Date period",
      reference: "Reference selection",
      commentlist: "Comments",
      filecollection: "File",
      subtable: "Subtable",
      dependent: "Dependent field",
      dependentsubtable: "Dependent table",
      dependentreference: "Dependent reference",
      grid: "Layout",
      tabs: "Tabs",
      label: "Label"
    },
    property: {
      model: 'ID',
      label: 'Label',
      width: 'Width',
      height: 'Height',
      size: 'Size',
      showLabel: 'Show label',
      hideLabel: 'Hide Label',
      labelWidth: 'Label Width',
      labelVertical: 'Label on top',
      placeholder: 'Placeholder',
      left: 'Left',
      right: 'Right',
      center: 'Center',
      showInput: 'Display Input Box',
      min: 'Minimum',
      max: 'Maximum',
      step: 'Step',
      multiple: 'Multiple',
      filterable: 'Searchable',
      allowHalf: 'Allow Half',
      showAlpha: 'Support transparency options',
      option: 'Option',
      staticData: 'Static Data',
      remoteData: 'Remote Date',
      remoteFunc: 'Remote Function',
      value: 'Value',
      childrenOption: 'Sub-Option',
      defaultValue: 'Default Value',
      showType: 'Display type',
      isRange: 'Range Time',
      isTimestamp: 'Get time stamp',
      startPlaceholder: 'Placeholder of start time',
      endPlaceholder: 'Placeholder of end time',
      defaultType: 'Data Type',
      string: 'String',
      object: 'Object',
      array: 'Array',
      number: 'Number',
      boolean: 'Boolean',
      integer: 'Integer',
      float: 'Float',
      url: 'URL',
      email: 'E-mail',
      hex: 'Hexadecimal',
      gutter: 'Grid Spacing',
      columnOption: 'Column Configuration',
      span: 'Grid spans',
      justify: 'Horizontal Arrangement',
      justifyStart: 'Start',
      justifyEnd: 'End',
      justifyCenter: 'Center',
      justifySpaceAround: 'Space Around',
      justifySpaceBetween: 'Space Between',
      align: 'Vertical Arrangement',
      alignTop: 'Top',
      alignMiddle: 'Middle',
      alignBottom: 'Bottom',
      type: 'Type',
      default: 'Default',
      card: 'Tabs',
      borderCard: 'Border-Card',
      tabPosition: 'Tab Position',
      top: 'Top',
      bottom: 'Bottom',
      tabOption: 'Label Configuration',
      tabName: 'Tab Name',
      customClass: 'Custom Class',
      attribute: 'Attribute Action',
      dataBind: 'Data Binding',
      hidden: 'Hidden',
      readonly: 'Read Only',
      disabled: 'Disabled',
      editable: 'Text box is editable',
      clearable: 'Display Clear Button',
      arrowControl: 'Use the arrow for time selection',
      isDelete: 'Deletable',
      isEdit: 'Editable',
      showPassword: 'Display Password',
      validate: 'Validation',
      required: 'Required',
      patternPlaceholder: 'Fill in the regular expressions',
      newOption: 'New Option',
      tab: 'Tab',
      validatorRequired: 'Required',
      validatorType: 'Invaild format',
      validatorPattern: 'Unmatched pattern',
      editableStatuses: 'Editable in statuses',
      bold: 'Bold',
      italic: 'Italic',
      precision: 'Precision',
    },
    action: {
      addColumn: 'Add Column',
      addTab: 'Add Tab'
    }
  }
}

export default {
  locale: 'en-US',
  localeAntd,
  messages,
}
