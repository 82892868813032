import { createRouter, createWebHistory } from 'vue-router'


const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/dashboard'
  },
  {
    path: '/represent',
    name: 'Represent',
    component: () => import(/* webpackChunkName: "mainmenumanage"*/ '../views/Represent.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/mainmenumanage',
    name: 'Main Menu Manage',
    component: () => import(/* webpackChunkName: "mainmenumanage"*/ '../views/MainMenuManager/Manager.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/userrolemanage',
    name: 'User Role Manage',
    component: () => import(/* webpackChunkName: "userrolemanage"*/ '../views/UserRoleManager.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/usermanage',
    name: 'User Manage',
    component: () => import(/* webpackChunkName: "usermanage"*/ '../views/UserManager.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/commentmanage',
    name: 'Comment Manage',
    component: () => import(/* webpackChunkName: "commentmanage"*/ '../views/CommentManager.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/listView',
    name: 'ListView',
    component: () => import(/* webpackChunkName: "notfound"*/ '../views/NotFound.vue')
  },
  {
    path: '/listview/:type',
    name: 'NewListView',
    component: () => import(/* webpackChunkName: "listview"*/ '../views/base/ListView.vue'),
  },
  {
    path: '/programTitle',
    name: 'ProgramTitle',
    component: () => import(/* webpackChunkName: "programtitle"*/ '../views/DropDownEntities/ProgramTitle.vue'),
  },
  {
    path: '/laboratories',
    name: 'Laboratories',
    component: () => import(/* webpackChunkName: "laboratories"*/ '../views/DropDownEntities/Laboratories.vue'),
  },
  {
    path: '/profiles',
    name: 'Profiles',
    component: () => import(/* webpackChunkName: "profiles"*/ '../views/DropDownEntities/Profiles.vue'),
  },
  {
    path: '/statuses',
    name: 'Statuses',
    component: () => import(/* webpackChunkName: "statuses"*/ '../views/DropDownEntities/Statuses.vue'),
  },
  {
    path: '/fumos',
    name: 'Fumos',
    component: () => import(/* webpackChunkName: "fumos"*/ '../views/DropDownEntities/Fumos.vue'),
  },
  {
    path: '/programLevels',
    name: 'programLevels',
    component: () => import(/* webpackChunkName: "programLevels"*/ '../views/DropDownEntities/ProgramLevels.vue'),
  },
  {
    path: '/trainingDirections',
    name: 'TrainingDirections',
    component: () => import(/* webpackChunkName: "trainingDirections"*/ '../views/DropDownEntities/TrainingDirections.vue'),
  },
  {
    path: '/fgoses',
    name: 'Fgoses',
    component: () => import(/* webpackChunkName: "fgoses"*/ '../views/DropDownEntities/Fgoses.vue'),
  },
  {
    path: '/files',
    name: 'Files',
    component: () => import(/* webpackChunkName: "files"*/ '../views/DropDownEntities/Files.vue'),
  },
  {
    path: '/qualifications',
    name: 'Qualifications',
    component: () => import(/* webpackChunkName: "qualifications"*/ '../views/DropDownEntities/Qualifications.vue'),
  },
  {
    path: '/profActivityAreas',
    name: 'ProfActivityAreas',
    component: () => import(/* webpackChunkName: "profActivityAreas"*/ '../views/DropDownEntities/ProfActivityAreas.vue'),
  },
  {
    path: '/graduateQualifications',
    name: 'GraduateQualifications',
    component: () => import(/* webpackChunkName: "graduateQualifications"*/ '../views/DropDownEntities/GraduateQualifications.vue'),
  },
  {
    path: '/umo',
    name: 'Umo',
    component: () => import(/* webpackChunkName: "umo"*/ '../views/DropDownEntities/Umo.vue'),
  },
  {
    path: '/ugps',
    name: 'Ugps',
    component: () => import(/* webpackChunkName: "ugps"*/ '../views/DropDownEntities/Ugps.vue'),
  },
  {
    path: '/developmentTermNine',
    name: 'DevelopmentTermNine',
    component: () => import(/* webpackChunkName: "developmentTermNine"*/ '../views/DropDownEntities/DevTermNine.vue'),
  },
  {
    path: '/developmentTermEleven',
    name: 'DevelopmentTermEleven',
    component: () => import(/* webpackChunkName: "developmentTermEleven"*/ '../views/DropDownEntities/DevTermEleven.vue'),
  },
  {
    path: '/qualificationsNSC',
    name: 'QualificationsNSC',
    component: () => import(/* webpackChunkName: "qualificationsNSC"*/ '../views/DropDownEntities/QualificationsNSC.vue'),
  },
  {
    path: '/profStandard',
    name: 'ProfStandard',
    component: () => import(/* webpackChunkName: "ProfStandard"*/ '../views/DropDownEntities/ProfStandard.vue'),
  },
  {
    path: '/programDevelopers',
    name: 'ProgramDevelopers',
    component: () => import(/* webpackChunkName: "ProgramDevelopers"*/ '../views/DropDownEntities/ProgramDevelopers.vue'),
  },     
  {
    path: '/demoobject',
    name: 'DemoObject',
    component: () => import(/* webpackChunkName: "demoObject"*/ '../views/DemoObject.vue'),
  },
  {
    path: '/formdesigner',
    name: 'FormDesigner',
    component: () => import(/* webpackChunkName: "FormDesignerDemo"*/ '../views/FormDesignerDemo.vue'),
  },
  {
    path: '/allNews',
    name: 'AllNews',
    component: () => import(/* webpackChunkName: "news"*/ '../views/News/AllNews.vue'),
  },
  {
    path: '/reestrNews',
    name: 'ReestrNews',
    component: () => import(/* webpackChunkName: "news"*/ '../views/News/ReestrNews.vue'),
  },
  {
    path: '/singleRegistryNews/:id',
    name: 'SingleRegistryNews',
    component: () => import(/* webpackChunkName: "news"*/ '../views/News/SingleRegistryNews.vue'),
  },
  {
    path: '/singleNews/:id',
    name: 'SingleNews',
    component: () => import(/* webpackChunkName: "news"*/ '../views/News/SingleNews.vue'),
  },
  {
    path: '/usefulResources',
    name: 'UsefulResources',
    component: () => import(/* webpackChunkName: "usefulResources"*/ '../views/UsefulResources/AllResources.vue'),
  },
  {
    path: '/usefulResource/:id',
    name: 'UsefulResource',
    component: () => import(/* webpackChunkName: "usefulResources"*/ '../views/UsefulResources/SingleResource.vue'),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "listview"*/ '../views/Dashboard.vue'),
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: () => import(/* webpackChunkName: "listview"*/ '../views/Contacts.vue'),
  },
  {
    path: '/notfound',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "notfound"*/ '../views/NotFound.vue')
  },
  {
    path: '/notloggedin',
    name: 'NotLoggedIn',
    component: () => import(/* webpackChunkName: "notloggedin"*/ '../views/NotLoggedIn.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/notfound'
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
