import axios from "axios";
import config from "./axiosconfig";
import urlTemplate from "@/shared/url-template";
import resources from "./resources";

export default {
  getFooterInfo: async () =>
    (await axios.get(urlTemplate(resources.contacts.getFooterInfo), config)).data,
  getPageInfo: async () =>
    (await axios.get(urlTemplate(resources.contacts.getPageInfo), config)).data,
}
