<template>
  <div />
</template>

<script>
// antd core & themes styles
import 'ant-design-vue/lib/style/index.less'
import '@/@vb/css/vendors/antd/themes/default.less'
//import '@/@vb/css/vendors/antd/themes/dark.less'

// third-party plugins styles
import 'bootstrap/dist/css/bootstrap.min.css'
import 'he-tree-vue/dist/he-tree-vue.css'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
// import 'quill/dist/quill.core.css'
// import 'quill/dist/quill.snow.css'

// vb vendors styles
import '@/@vb/css/vendors/antd/style.scss'
import '@/@vb/css/vendors/bootstrap/style.scss'
// import '@/@vb/css/vendors/nprogress/style.scss'
import '@/@vb/css/vendors/he-tree-vue/style.scss'
import '@/@vb/css/vendors/perfect-scrollbar/style.scss'
import '@/@vb/css/vendors/quill/style.scss'

// vb styles
import '@/@vb/css/core.scss'
import '@/@vb/css/measurements.scss'
import '@/@vb/css/colors.scss'
import '@/@vb/css/utils.scss'
import '@/@vb/css/layout.scss'
import '@/@vb/css/router.scss'

// vb extra styles
//import '@/@vb/css/extra/clean.scss' // clean styles
//import '@/@vb/css/extra/air.scss' // air styles

import "@/css/sp.scss";

export default {
  name: 'StyleLoader',
  setup() {
    const version = 'fluent'
    const theme = 'default'

    document.querySelector('html').setAttribute('data-vb-version', version)
    document.querySelector('html').setAttribute('data-vb-theme', theme)
  },
}
</script>
