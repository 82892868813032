import axios from "axios";
import config from "./axiosconfig";
import urlTemplate from "@/shared/url-template";
import resources from "./resources";

export default {
  getRegistryNews: async (page) =>
    (await axios.get(urlTemplate(resources.registrynews.getRegistryNews, { page } ), config)).data,
  getSingleRegistryNews: async (id) =>
    (await axios.get(urlTemplate(resources.registrynews.getSingleRegistryNews, {id} ), config)).data,
}