import axios from "axios";
import config from "./axiosconfig";
import urlTemplate from "@/shared/url-template";
import resources from "./resources";

export default {
  gridData: async (type, parentId, filters = {}) =>
    (await axios.post(urlTemplate(resources.subtable.gridData, { type, parentId }), { filters }, config)).data,
  create: async (type, parentId, entity) =>
    (await axios.post(urlTemplate(resources.subtable.create, { type, parentId }), entity, config)).data,
}
