import { ref } from "vue";
import axios from "axios";
import sortBy from "lodash/sortBy";

const mainMenu = ref([]);

const initMenu = async () => {
  const data = (await axios.get('MainMenu')).data;
  mainMenu.value = parseMenuItems(data);
}

function parseMenuItems(items) {
  return sortBy(items.map(item => ({
    title: item.title,
    key: item.id,
    url: item.url,
    icon: item.icon,
    sortOrder: item.sortOrder,
    children: item.children?.length ? parseMenuItems(item.children) : null
  })), x => x.sortOrder);
}

export default {
  initMenu,
  mainMenu: mainMenu,
}