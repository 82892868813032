import { reactive } from "vue";
import axios from "axios";

const state = reactive({
  userName: null,
  user: null
});
const getUserName = (user) => {
  return user.name;
}

export default { 
  state,
  isLoggedIn() {
    return !!state.user;
  },
  async logout() {
    await axios.post("account/logout");
    state.user = null;
    state.userName = null;
  },
  async login(login, password) {
    let data = (await axios.post("account/login", { login: login, password: password })).data;
    if (!data || data.error)
      return false;
    state.userName = getUserName(data);
    state.user = data;
    return true;
  },
  async loadCurrentUser() {
    let data;
    try {
      data = (await axios.get("account")).data;
    }
    catch (e) {
      return;
    }
    if (!data || data.error)
      return;
    state.userName = getUserName(data);
    state.user = data;
  }
};