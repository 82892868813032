import axios from "axios";
import config from "./axiosconfig";
import urlTemplate from "@/shared/url-template";
import resources from "./resources";

export default {
  getPublishedComments: async (programId) =>
    (await axios.get(urlTemplate(resources.comment.getPublishedComments, { programId }), config)).data,
  createComment: async (programId, comment) =>
    (await axios.post(urlTemplate(resources.comment.createComment, { programId }), comment, config)).data,
}
