export default {
  use: function(router, options) {
    if (!options)
      throw new Error('Options not set');
    if (!options.auth)
      throw new Error('Auth service is not set');
    if (!options.notloggedin)
    throw new Error('notloggedin path service is not set');

    router.beforeEach((to, from, next) => {
      if (to.matched.some((record) => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!options.auth.isLoggedIn()) {
          next({
            path: options.notloggedin,
            query: { redirect: to.fullPath },
          });
        } 
        else next();
      }
      else next();
    });    
  }
}