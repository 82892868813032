import axios from "axios";
import config from "./axiosconfig";
import urlTemplate from "@/shared/url-template";
import resources from "./resources";

export default {
  getYears: async () =>
    (await axios.get(urlTemplate(resources.diagramchart.getYears, {} ), config)).data,
  getMonths: async () =>
    (await axios.get(urlTemplate(resources.diagramchart.getMonths, {} ), config)).data,
  getLabs: async (filter) =>
    (await axios.post(urlTemplate(resources.diagramchart.getLabs, {} ), filter, config)).data,
}
