import axios from "axios";
import config from "./axiosconfig";
import urlTemplate from "@/shared/url-template";
import resources from "./resources";

export default {
  getLast: async () =>
    (await axios.get(urlTemplate(resources.dataslicestatistic.last, {} ), config)).data,
  getFilters: async () =>
    (await axios.get(urlTemplate(resources.dataslicestatistic.filters, {} ), config)).data,
}
