import axios from 'axios';

export async function loadConfig() {
  let config = null;

  if (process.env.NODE_ENV === "production") {
    try {
      config = (await axios.get("/config/config.prod.json")).data;
    }
    /* eslint-disable no-empty */
    catch (e) {}
  }
  else {
    try {
      config = (await axios.get("/config/config.dev.json")).data;
    }
    /* eslint-disable no-empty */
    catch (e) { }
  }
  if (config == null)
    config = (await axios.get("/config/config.json")).data;

  axios.defaults.baseURL = config.backendUrl;
}