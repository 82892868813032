<template>
  <a-layout-footer
    class="vb__layout__footer"
    :style="
            !$viewport.state.isMenuCollapsed
              ? 'width: calc(100% - 260px);'
              : 'width: calc(100% - 80px);'
          "
  >
    <div class="vb__layout__footer__copyright">
      {{ footer.copyrightFooter }}
    </div>
    <ul class="vb__layout__footer__social">
      <li>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="29"
          height="29"
          fill="none"
          viewBox="0 0 29 29"
        >
          <path
            fill="#000"
            fill-opacity=".5"
            d="M14.5 0C8.379 0 3.398 4.98 3.398 11.102a11.097 11.097 0 002.256 6.708L14.018 29h.964l8.364-11.19a11.097 11.097 0 002.256-6.709C25.602 4.982 20.622 0 14.5 0zm0 14.783a3.686 3.686 0 01-3.682-3.681A3.686 3.686 0 0114.5 7.42a3.686 3.686 0 013.682 3.682 3.686 3.686 0 01-3.682 3.681z"
          />
        </svg>

        {{ footer.addressFooter }}
      </li>
      <li>
        <a :href="`mailto:${ footer.mailFooter }`">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="29"
          height="29"
          fill="none"
          viewBox="0 0 29 29"
        >
          <g clip-path="url(#clip0_514_2478)">
            <path
              fill="#000"
              fill-opacity=".5"
              d="M18.792 15.704l-2.43 2.437c-.984.988-2.717 1.01-3.723 0l-2.43-2.437-8.723 8.752c.325.15.683.24 1.064.24h23.902c.38 0 .739-.09 1.063-.24l-8.723-8.752z"
            />
            <path
              fill="#000"
              fill-opacity=".5"
              d="M26.452 4.305H2.55c-.381 0-.74.09-1.064.24l9.322 9.352.002.002v.001l3.032 3.041c.322.322.996.322 1.318 0l3.03-3.04.002-.002.002-.002 9.321-9.353a2.522 2.522 0 00-1.063-.24zM.271 5.733C.103 6.073 0 6.45 0 6.853v15.294c0 .404.103.78.271 1.12l8.739-8.766L.27 5.732zM28.729 5.732L19.99 14.5l8.739 8.767c.168-.34.27-.717.27-1.12V6.852c0-.404-.102-.78-.27-1.12z"
            />
          </g>
          <defs>
            <clipPath id="clip0_514_2478">
              <path fill="#fff" d="M0 0h29v29H0z" />
            </clipPath>
          </defs>
        </svg>
          <span>{{ footer.mailFooter }}</span>
        </a>
      </li>
      <li>
        <a :href="`tel:${ formatPhoneFooter }`">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="29"
          height="29"
          viewBox="0 0 29 29"
        >
          <g clip-path="url(#clip0_514_2506)">
            <path
              fill="#000"
              fill-opacity=".5"
              d="M24.752 4.245c-5.664-5.662-14.845-5.66-20.507.003-5.661 5.664-5.66 14.844.004 20.506 5.663 5.662 14.844 5.66 20.506-.003a14.5 14.5 0 00-.003-20.506zM21.96 20.198l-.002.002v-.005l-.735.73a3.867 3.867 0 01-3.649 1.044 12.85 12.85 0 01-3.731-1.672 17.243 17.243 0 01-2.944-2.374A17.38 17.38 0 018.7 15.255a13.533 13.533 0 01-1.653-3.344 3.867 3.867 0 01.967-3.95l.86-.86a.614.614 0 01.868-.001l.002.001 2.716 2.717c.24.239.241.628.002.868l-.002.002-1.595 1.595a1.3 1.3 0 00-.135 1.692 18.642 18.642 0 001.91 2.228 18.53 18.53 0 002.512 2.112 1.31 1.31 0 001.673-.145l1.542-1.566a.614.614 0 01.868-.002l.002.002 2.72 2.726c.241.239.242.628.003.868z"
            />
          </g>
          <defs>
            <clipPath id="clip0_514_2506">
              <path fill="#fff" d="M0 0h29v29H0z" />
            </clipPath>
          </defs>
        </svg>
        <span>{{ footer.phoneFooter }}</span>
        </a>
      </li>
    </ul>
  </a-layout-footer>
</template>
<script>
import api from "@/api";
export default {
  name: "SpFooter",
  data() {
    return {
      footer: {},
    };
  },
  async created() {
    this.footer = await api.contacts.getFooterInfo();
  },
  computed: {
    formatPhoneFooter() {
      return this.footer?.phoneFooter?.replace(
        /[`~!@#$%^&*()_|\-=?;:'",.<>, " "]/g,
        ''
      );
    },
  },
};
</script>

<!--<style lang="scss" module>-->
<!--@import './style.module.scss';-->
<!--</style>-->
