import axios from "axios";
import config from "./axiosconfig";
import urlTemplate from "@/shared/url-template";
import resources from "./resources";

export default {
  gridData: async (type, filters = {}, pageSkip = 0, pageSize = 0) =>
    (await axios.post(urlTemplate(resources.crud.gridData, { type: type }), {filters: filters, pageSkip: pageSkip, pageSize: pageSize }, config)).data,
  create: async (type, entity) =>
    (await axios.post(urlTemplate(resources.crud.create, { type: type }), entity, config)).data,
  update: async (type, entity) =>
    (await axios.put(urlTemplate(resources.crud.update, { type: type }), entity, config)).data,
  get: async (type, id) =>
    (await axios.get(urlTemplate(resources.crud.get, { type: type, id: id }), config)).data,
  getPermissions: async (type, id) =>
    (await axios.get(urlTemplate(resources.crud.getPermissions, { type: type, id: id }), config)).data,
  remove: async (type, id) =>
    (await axios.delete(urlTemplate(resources.crud.remove, { type: type, id: id }), config)).data,
  resetspbp: async (id) =>
    (await axios.get("admin/reset/spbp/" + id, config)).data,
}
