import axios from "axios";
import config from "./axiosconfig";
import urlTemplate from "@/shared/url-template";
import resources from "./resources";

export default {
  myRole: async () =>
    (await axios.get(urlTemplate(resources.account.myRole), config)).data,
  myUser: async () =>
    (await axios.get(urlTemplate(resources.account.myUser), config)).data,
}
