import axios from "axios";
import config from "./axiosconfig";
import urlTemplate from "@/shared/url-template";
import resources from "./resources";

export default {
  getchartAllData: async (filter) =>
    (await axios.post(urlTemplate(resources.labchart.getchartAllData, {} ), filter, config)).data,
  getchartData: async (filter) =>
    (await axios.post(urlTemplate(resources.labchart.getchartData, {} ), filter, config)).data,
  getstatusData: async (filter) =>
    (await axios.post(urlTemplate(resources.labchart.getstatusData, {} ), filter, config)).data,
  getlevelData: async (filter) =>
    (await axios.post(urlTemplate(resources.labchart.getlevelData, {} ), filter, config)).data,
}
