import axios from "axios";
import config from "./axiosconfig";
import urlTemplate from "@/shared/url-template";
import resources from "./resources";

export default {
  gridData: async (type, ownerId, filters = {}) =>
    (await axios.post(urlTemplate(resources.subtableEx.gridData, { type, ownerId }), { filters }, config)).data,
  create: async (type, ownerId, entity) =>
    (await axios.post(urlTemplate(resources.subtableEx.create, { type, ownerId }), entity, config)).data,
}
