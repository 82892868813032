<template>
  <style-loader />
  <localization>
    <a-layout class="vb__layout vb__layout__cardsShadow">
      <sp-main-menu />
      <a-layout>
        <a-layout-header class="vb__layout__header vb__layout__fixedHeader">
          <sp-topbar />
        </a-layout-header>
        <a-layout-content class="vb__layout__content">
          <router-view v-slot="{ Component }">
            <transition name="fadein" mode="out-in">
              <component :is="Component" />
            </transition>
          </router-view>
        </a-layout-content>
        <sp-footer v-show="footerInit" />
      </a-layout>
    </a-layout>
  </localization>
</template>

<script>
import StyleLoader from "@/style-loader.vue";
import Localization from "@/localization.vue";
import SpMainMenu from "@/components/sp-main-menu";
import SpTopbar from "@/components/sp-topbar";
import SpFooter from "@/components/sp-footer";
// import api from "./api";

export default {
  name: "LayoutDefault",
  components: {
    StyleLoader,
    Localization,
    SpMainMenu,
    SpTopbar,
    SpFooter
  },
  data() {
    return {
      // footer: {},
      footerInit: false,
    };
  },
  async created() {
    this.$viewport.bindMobileSlide();
    this.$viewport.detectViewPort(true);
    window.addEventListener("resize", this.$viewport.detectViewPortListener);

    // this.footer = await api.contacts.getFooterInfo();
    this.footerInit = true;
  },
};
</script>
