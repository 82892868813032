export default {
  crud: {
    gridData: "crud/{type}/grid-data",
    create: "crud/{type}",
    get: "crud/{type}/{id}",
    getPermissions: "crud/{type}/{id}/permissions",
    update: "crud/{type}",
    remove: "crud/{type}/{id}",
  },

  entityConfig: {
    represent: "represent/{represent}",
    representEntityFields: "represent/entity_fields/{represent}",
    representRefreshListviewPresets: "represent/refresh_listview_presets",
    filteredFields: "represent/filtered-fields",
    userRoleTypes: "represent/user_role_types"
  },
  
  entityDynamicField: {
    getFieldTypes: "entity_dynamic_field/field_types"
  },

  reference: {
    getOptions: "reference/{type}"
  },

  subtable: {
    gridData: "subtable/{type}/grid-data{?parentId}",
    create: "subtable/{type}{?parentId}",
  },

  subtableEx: {
    gridData: "subtable_ex/{type}/grid-data{?ownerId}",
    create: "subtable_ex/{type}{?ownerId}",
  },

  dependent: {
    getEntityField: "dependent/{entityName}/{id}/{fieldName}",
    gridData: "{targetType}/{sourceType}/{id}{?programId}",
  },

  news: {
    getNews: "news{?page}",
    getHeroNews: "news/hero",
    getSingleNews: "news/{id}"
  },

  registrynews: {
    getRegistryNews: "registrynews{?page}",
    getSingleRegistryNews: "registrynews/{id}"
  },

  usefulResources: {
    getResources: "useful_resource{?page}",
    getSingleResource: "useful_resource/{id}"
  },

  diagramchart: {
    getYears: "diagramchart/years",
    getMonths: "diagramchart/months",
    getLabs: "diagramchart/labs",
  },

  barchart: {
    getYears: "barchart/years",
    getData: "dataslicestatistic/spcount",
    getLabs: "barchart/labs",
  },

  dataslicestatistic: {
    last: "dataslicestatistic/last",
    filters: "dataslicestatistic/filters",
  },

  labchart: {
    getchartAllData: "dataslicestatistic/labs",
    getchartData: "dataslicestatistic/ugps",
    getstatusData: "dataslicestatistic/status",
    getlevelData: "dataslicestatistic/level",
  },

  filestorage: {
    uploadFile: "file/upload/{directory}/{filename}",
    downloadFile: "file/download/{id}",
    getInfo: "file/info/{id}",
  },

  account: {
    myRole: "account/role",
    myUser: "account",
  },

  programStatus: {
    getAll: "program_status"
  },

  programTitle: {
    getAll: "program_title"
  },

  comment: {
    getPublishedComments: "comment{?programId}",
    createComment: "comment/{programId}"
  },

  upload: {
    getAllowedTypes: "upload/types",
  },

  contacts: {
    getFooterInfo: "contacts/footer",
    getPageInfo: "contacts/page"
  }
}